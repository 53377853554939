// extracted by mini-css-extract-plugin
export var banner = "milestones-module--banner--213f9";
export var btnDark = "milestones-module--btn-dark--a54ba";
export var btnLight = "milestones-module--btn-light--fd4b9";
export var buttonDefault = "milestones-module--button-default--a50ea";
export var buttonLarge = "milestones-module--button-large--949e0";
export var buttonSmall = "milestones-module--button-small--b641f";
export var ctaLarge = "milestones-module--cta-large--754f0";
export var ctaSmall = "milestones-module--cta-small--ef026";
export var highlight = "milestones-module--highlight--b9056";
export var input = "milestones-module--input--dedd0";
export var introSection = "milestones-module--intro-section--037d2";
export var labelLarge = "milestones-module--label-large--59a43";
export var labelMedium = "milestones-module--label-medium--16e5c";
export var languages = "milestones-module--languages--bf7f0";
export var link = "milestones-module--link--98972";
export var list = "milestones-module--list--09e14";
export var listSection = "milestones-module--list-section--4ea9e";
export var listTextWithHeader = "milestones-module--list-text-with-header--59d03";
export var mainText = "milestones-module--main-text--69b30";
export var mainTitle = "milestones-module--main-title--93a9d";
export var optionContainer = "milestones-module--optionContainer--aa389";
export var optionListContainer = "milestones-module--optionListContainer--b169c";
export var pLarge = "milestones-module--p-large--0598b";
export var pLargeBold = "milestones-module--p-large-bold--3c823";
export var pMedium = "milestones-module--p-medium--1b7a8";
export var pSmall = "milestones-module--p-small--d01ea";
export var searchWrapper = "milestones-module--search-wrapper--ca56a";
export var singleChip = "milestones-module--singleChip--9152e";
export var slickNext = "milestones-module--slick-next--86948";
export var slickPrev = "milestones-module--slick-prev--8ea4b";
export var slickSlide = "milestones-module--slick-slide--e0680";
export var titleLarge = "milestones-module--title-large--b1d0b";
export var titleMedium = "milestones-module--title-medium--63621";
export var titleSmall = "milestones-module--title-small--00e87";
export var titleXl = "milestones-module--title-xl--b6863";