import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as herobannerStyles from "../styles/components/herobanner.module.scss";
import classNames from "classnames";
import parse from "html-react-parser";

const Herobanner = ({ herobanner }) => {
  const image = getImage(herobanner.image_group.image);
  let splitText = herobanner.text.split("<br/>").filter((el) => el !== "");
  let imageClassname = `${herobanner.image_group.image_position}BannerImage`;
  return (
    <section
      className={classNames(
        herobannerStyles.banner,
        herobannerStyles[imageClassname]
      )}
    >
      <div className={herobannerStyles.bannerContent}>
        {herobanner.secondary_header && (
          <label
            className={classNames(
              herobannerStyles.secondaryHeader,
              "label-medium"
            )}
          >
            {herobanner.secondary_header}
          </label>
        )}
        <h1 className={herobannerStyles.mainTitle}>{herobanner.title}</h1>
        {herobanner.secondary_header_text && (
          <p className={classNames(herobannerStyles.secondaryHeaderText)}>
            {herobanner.secondary_header_text}
          </p>
        )}
        <p className="p-medium">
          {splitText.map((text, idx) => (
            <span className={herobannerStyles.bannerText} key={idx}>
              {parse(text)}
            </span>
          ))}
        </p>
      </div>
      <div className={herobannerStyles.bannerImageBlock}>
        <GatsbyImage
          image={image}
          height="100%"
          width="100%"
          alt={herobanner?.image_group?.image?.title}
          aria-label="Hero Banner Image"
          loading="eager"
        />
      </div>
    </section>
  );
};

export default Herobanner;
