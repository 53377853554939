import React from "react";
import * as milestonesStyles from "../styles/components/milestones.module.scss";
import parse from "html-react-parser";

const Milestonesbanner = ({ milestones }) => {
  return (
    <section
      className={milestonesStyles.banner}
      style={{
        backgroundImage: `url(${milestones?.whitespace_image?.logo_image?.url})`,
      }}
    >
      <div className={milestonesStyles.introSection}>
        <label className="label-large">{milestones.secondary_header}</label>
        <h2 className={milestonesStyles.mainTitle}>{milestones.title}</h2>
        <p className={milestonesStyles.mainText}>{milestones.text}</p>
      </div>
      <div className={milestonesStyles.listSection}>
        <label className="label-large">{milestones.list_header}</label>
        {milestones.list_sections.map((list_section, idx) => (
          <div className={milestonesStyles.list} key={idx}>
            {list_section.list_sub_header && (
              <label className="label-medium">
                {list_section.list_sub_header}
              </label>
            )}
            <div
              className={`p-medium ${
                list_section.list_sub_header
                  ? milestonesStyles.listTextWithHeader
                  : ""
              }`}
            >
              {parse(list_section.list_text)}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Milestonesbanner;
